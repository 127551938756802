import getNetResource from './netConfig'

const userData = {
  authenticate({ user, pass, siteName }) {
    return getNetResource('/authenticate', 'post', { accountName: user, password: pass, site: siteName })
  },
  activateAccount(passData) {
    return getNetResource('/passwords/changeUserPassword', 'post', passData)
  },
  createGuardUser(user) {
    return getNetResource('/users/create-guard-user', 'post', user)
  },
  deleteGuardUser(user) {
    return getNetResource('/users/delete-guard-user', 'post', user)
  },
  disableGuardUser(user) {
    return getNetResource('/users/disable-guard-user', 'post', user)
  },
  enableGuardUser(user) {
    return getNetResource('/users/enable-guard-user', 'post', user)
  },
  createPrinterUser(user) {
    return getNetResource('/users/create-printer-user', 'post', user)
  },
  deletePrinterUser(user) {
    return getNetResource('/users/delete-printer-user', 'post', user)
  },
  disablePrinterUser(user) {
    return getNetResource('/users/disable-printer-user', 'post', user)
  },
  enablePrinterUser(user) {
    return getNetResource('/users/enable-printer-user', 'post', user)
  },
  createApproverDataUpload(approverDataUploadBody) {
    return getNetResource('/approver-datauploads/create', 'post', approverDataUploadBody)
  },
  createApproverUser(user) {
    return getNetResource('/users/create-approver-user', 'post', user)
  },
  deleteApproverUser(user) {
    return getNetResource('/users/delete-approver-user', 'post', user)
  },
  disableApproverUser(user) {
    return getNetResource('/users/disable-approver-user', 'post', user)
  },
  enableApproverUser(user) {
    return getNetResource('/users/enable-approver-user', 'post', user)
  },
  createEnterpriseUser(user) {
    return getNetResource('/users/create-enterprise-user', 'post', user)
  },
  deleteEnterpriseUser(user) {
    return getNetResource('/users/delete-enterprise-user', 'post', user)
  },
  disableEnterpriseUser(user) {
    return getNetResource('/users/disable-enterprise-user', 'post', user)
  },
  enableEnterpriseUser(user) {
    return getNetResource('/users/enable-enterprise-user', 'post', user)
  },
  createSupervisorUser(user) {
    return getNetResource('/users/create-supervisor-user', 'post', user)
  },
  deleteSupervisorUser(user) {
    return getNetResource('/users/delete-supervisor-user', 'post', user)
  },
  disableSupervisorUser(user) {
    return getNetResource('/users/disable-supervisor-user', 'post', user)
  },
  enableSupervisorUser(user) {
    return getNetResource('/users/enable-supervisor-user', 'post', user)
  },
  sendNewPass(userEmail,userType) {
    let formData = new FormData()
    formData.append('email', userEmail)
    formData.append('userType', userType)
    return getNetResource('/passwords/sendTemporaryUserPassword', 'post', formData)
  },
  sendNewPassFn(args) {
    let formData = new FormData()
    formData.append('email', args.email)
    formData.append('userType', args.userType)
    return getNetResource('/passwords/sendTemporaryUserPassword', 'post', formData)
  },
  getUserAdditionalData({uuid}) {
    return getNetResource(`/users/get-additional-data/${uuid}`, 'get')
  },
  getUserInfo(userUuid) {
    return getNetResource(`/users/getUserInfo/${userUuid}`, 'get')
  },
  getGuardUsers(filter) {
    return getNetResource(`/users/get-guard-users/${filter}`, 'get')
  },
  getPrinterUsers(filter) {
    return getNetResource(`/users/get-printer-users/${filter}`, 'get')
  },
  getApproverUsers(filter) {
    return getNetResource(`/users/get-approver-users/${filter}`, 'get')
  },
  getApproverUsersInOffice({filter, officeCode}) {
    return getNetResource(`/users/get-approver-users/${filter}/office/${officeCode}`, 'get')
  },
  getApproverUsersInOfficeWithAccountName({filter, accountName}) {
    return getNetResource(`/users/get-approver-users/${filter}/accountName/${accountName}`, 'get')
  },
  getSupervisorUsersInOffice({filter, officeCode}) {
    return getNetResource(`/users/get-supervisor-users/${filter}/office/${officeCode}`, 'get')
  },
  getEnterpriseUsers(filter) {
    return getNetResource(`/users/get-enterprise-users/${filter}`, 'get')
  },
  getQrProof({ userId, size }) {
    return getNetResource(`/templates/getQr/${userId}/${size}`, 'get')
  },
  getBarcode({ userId, size, typeName }) {
    return getNetResource(`/templates/getBarcode/${userId}/${typeName}/${size}`, 'get')
  },
  getUserDataState(userUuid) {
    return getNetResource(`/request/getRequestState/${userUuid}`, 'get')
  },
  createRequestUserData({ userUuid, data }) {
    let changeRequest = {
      templateId: data.templateId,
      userUuid: userUuid,
      reasons: data.reasons,
      data: data.data
    }
    return getNetResource('/request/create', 'post', changeRequest)
  },  
  createRequestUserPhoto({ userUuid, newPhoto, reasons }) {
    let formData = new FormData()
    formData.append('imagesFile', newPhoto)
    formData.append('reasons', reasons)
    formData.append('userUuid', userUuid)

    return getNetResource('/request/updatePhotoProposed', 'post', formData)
  },
  replyRequest(user) {
    return getNetResource('/request/reply', 'post', user)
  },
  sendRefreshToken(args) {
    return getNetResource('/refresh-token', 'post', args)
  },
  approveIdData(userUuid) {
    return getNetResource(`/request/accept/${userUuid}`, 'get')
  },
  saveAccess({guardId,userId}) {
    let formData = new FormData()
    formData.append('guardId', guardId)
    formData.append('userId', userId)
    return getNetResource('/users/guards/saveAccess', 'post',formData)
  },
  getAccessLog(config) {
    return getNetResource('/users/guards/getAccess', 'post',config)
  },
  getPrintHistory({uuid}) {
    return getNetResource(`/users/getPrintHistory/${uuid}`, 'get')
  },
  getPrintHistoryReport({uuid}) {
    return getNetResource(`/users/getPrintHistoryReport/${uuid}/true`, 'get')
  }
}

export default userData
