import getNetResource from './netConfig'

const officeData = {
    getOffices(args) {
      return getNetResource('/offices/getOffices', 'post', args)
    },    
    createOffice(args) {
      return getNetResource('/offices/create', 'post', args)
    },
    updateOffice(args) {
      return getNetResource('/offices/update', 'post', args)
    },
}

export default officeData