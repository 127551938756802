import axios from 'axios'

const baseURL =
   import.meta.env.NODE_ENV === 'production' ?
     { baseURL: 'https://'+import.meta.env.VITE_BASE_URL }
     :  { baseURL: 'https://'+import.meta.env.VITE_BASE_URL }

export default axios.create(
  baseURL
)
